import React, { memo } from 'react'
import { getFeaturedCompanies } from '../apiClient'
import { ELanguage } from '@microfrontends/app-shell-v2'
import { TCompany } from './type'
import AppContext from '../../../context/AppContext'
import dynamic from 'next/dynamic'
import SectionBlock from '../../sectionBlock'

const CompanyBlock = dynamic(() => import('./companyBlock'))

type TSFeaturedCompaniesStates = {
	companies: TCompany[]
	forceHide: boolean
}

interface TFeaturedCompaniesProps {
	companies: TCompany[]
	langCode: ELanguage
}

class FeaturedCompanies extends React.Component<
	TFeaturedCompaniesProps,
	TSFeaturedCompaniesStates
> {
	static contextType = AppContext
	declare context: React.ContextType<typeof AppContext>
	constructor(props: TFeaturedCompaniesProps) {
		super(props)

		this.state = {
			companies: this.props?.companies || [],
			forceHide: false
		}
	}
	async componentDidMount() {
		const { langCode, companies } = this.props
		if (!companies || companies?.length === 0) {
			const companies = await getFeaturedCompanies(langCode)
			if (companies.length > 0) {
				this.setState({
					companies: companies
				})
			} else {
				this.setState({ forceHide: true })
			}
		}
	}

	renderCompanyList() {
		const { companies } = this.state
		return companies.map((company, index) => (
			<CompanyBlock
				key={index}
				name={company.name}
				logo={company.logo}
				url={company.url}
				hasNewJobs={true}
				companyLength={companies.length}
				targetBlank={true}
			/>
		))
	}

	render() {
		const { forceHide } = this.state
		if (forceHide) return null
		/*
		 * @Sang nguyen
		 * Removed tet theme
		 * */
		let sectionClass: string[] = [
			'sectionBlock_has-padding-touch sectionBlock_featured-company'
		]
		const titleText = this.context?.t('Featured Companies')
		return (
			<SectionBlock title={titleText} sectionClass={sectionClass}>
				<div className='featured-companies'>
					{this.renderCompanyList()}
				</div>
			</SectionBlock>
		)
	}
}

const FeaturedCompaniesCom = FeaturedCompanies
export default memo(FeaturedCompaniesCom)
